import React, {FC, InputHTMLAttributes} from 'react';
import styles from "./input.module.scss";

interface IInputProps extends InputHTMLAttributes<HTMLInputElement> {
    name: string;
    labelText?: string;

    className?: string;
}

const Input: FC<IInputProps> = ({ name, labelText, className, ...rest }) => {

    return (
        <div className={className ? `${className} ${styles.customInput}` : styles.customInput}>
            {labelText && <label htmlFor={name}>{labelText}</label>}
            <input name={name} {...rest}/>
        </div>
    );
};

export default Input;