import React, {FC, useEffect, useState} from 'react';
import $api from "../../api";

interface Props {
    children?: React.ReactNode;
    className?: string
}

const ButtonCustom: FC<Props> = ({ children, className}) => {

    const [link, setLink] = useState<string>('https://google.com');

    useEffect(() => {
        (async () => {
            const res = await $api.get("/contacts");
            if (res.data) {
                setLink(res.data.link);
            }
        })();
    }, [])

    return (
        <a className={className ? `${className} button` : "button"} href={link} >
            {children}
        </a>
    );
};

export default ButtonCustom;