import React, {FC, useEffect, useState} from 'react';
import SectionTop from "../../sections/section_top/SectionTop";
import double_window_bg from "../../assets/images/double_window_bg.png"
import guarant_window_left from "../../assets/images/guarant-window-left.png"
import guarant_window_right from "../../assets/images/guarant-window-right.png"
import ButtonCustom from "../../components/button/ButtonCustom";
import SectionGuarantee from "../../sections/section_guarantee/SectionGuarantee";
import SectionWindowsDelivery from "../../sections/section_windows_delivery/SectionWindowsDelivery";
import order from "../../assets/images/order-bg.png";
import SectionContactUs from "../../sections/section_contact_us/SectionContactUs";
import SectionSlider from "../../sections/section_slider/SectionSlider";
import $api from "../../api";
import ButtonForm from "../../components/button/ButtonForm";
import {useAppDispatch} from "../../store/reducers/hooks";
import {showModal} from "../../store/modal/modal-slice";
import FormSubmitYourApplication
    from "../../components/forms/form-submit-your-application/form-submit-your-application";

const DoubleWindow: FC = () => {
    const [product, setProduct] = useState<IProduct>()
    const dispatch = useAppDispatch();

    const onForm = () => {
        dispatch(showModal(<FormSubmitYourApplication />))
    }

    useEffect(() => {
        (async () => {
            const res = await $api.get("/products?name=Double window")
            setProduct(res.data);
        })()
    }, [])

    return (
        <div>
            <SectionTop backgroundImg={double_window_bg}>
                <h1 className={"section_top__title"}>
                    <p>DOUBLE WINDOW</p>
                </h1>
                <p className={"section_top__subtitle"}>
                    Two operable sashes move vertically in the frame
                    and counterbalance mechanisms hold
                    the sash open or closed. Double hung windows
                    are easy to clean because of the variety
                    of ways that you can tilt and open them
                    – this is especially convenient for homes
                    with more than one level. Global Window NYC double hung windows offer
                    a lifetime of beauty and comfort.
                </p>
                <div className={"buttonWrap"}>
                    <ButtonCustom className={"section_top__button"}>GET PRICE</ButtonCustom>
                    <ButtonForm onClick={onForm}>SUBMIT YOUR APPLICATION</ButtonForm>
                </div>
            </SectionTop>
            <SectionGuarantee
                imgLeft={guarant_window_left}
                imgRight={guarant_window_right}
                installation_periods={1}
                on_products_years={10}
            />
            { product && product.productPhotos && <SectionSlider name={product.name} slides={product.productPhotos} /> }
            <SectionWindowsDelivery />
            <SectionContactUs forOrder="window" backgroundOrder={order} />
        </div>
    );
};

export default DoubleWindow;