import React, {FC, useEffect, useState} from 'react';
import Slider, {Settings} from "react-slick";
import windowSlide1 from "../../assets/images/window_slider/1.jpeg";
import $api from "../../api";

const SectionReviews: FC = () => {
    const settings: Settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    dots: false,
                    infinite: false,
                    speed: 500,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    dots: false,
                    infinite: false,
                    speed: 500,
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const [reviews, setReviews] = useState<IReview[]>([])

    useEffect(() => {
        (async () => {
            const res = await $api.get("/reviews")
            setReviews(res.data);
        })()
    }, [])

    if (!reviews.length) {
        return null;
    }

    return (
        <div id="reviews-element" className={"section_review"}>
            <div className="container">
                <div className={"section_review__content"}>
                    <div className={"section_review__top"}>
                        <p>REVIEWS</p>
                    </div>
                    <Slider {...settings}>
                        {reviews.map(item =>
                                <div key={item.id}>
                                    <div className={"section_review__wrap"}>
                                        <div className={"section_review__ava_wrap"}>
                                            <div className={"section_review__ava"}>
                                                <img src={process.env.NODE_ENV === "development" ? `${process.env.REACT_APP_URL_DEVELOPMENT}/public/reviewsAvatar/${item.nameAva}` : `${process.env.REACT_APP_URL_PRODUCTION}/public/reviewsAvatar/${item.nameAva}`} alt=""/>
                                            </div>
                                        </div>
                                        <div className={"section_review__text"}>
                                            {item.text}
                                        </div>
                                    </div>
                                </div>
                        )}
                    </Slider>
                </div>
            </div>
        </div>
    );
};

export default SectionReviews;