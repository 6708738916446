import React, {useState} from 'react';
import Input from "../../inputs/input";
import ButtonForm from "../../button/ButtonForm";
import styles from "./form-submit-your-application.module.scss";
import $api from "../../../api";
import {hideModal} from "../../../store/modal/modal-slice";
import {useAppDispatch} from "../../../store/reducers/hooks";
import { InputMask } from '@react-input/mask';

const FormSubmitYourApplication = () => {
    const dispatch = useAppDispatch();

    const [name, setName] = useState<string>("");
    const [phone, setPhone] = useState<string>("");
    const [error, setError] = useState<string | null>(null);
    const [isSuccess, setIsSuccess] = useState<boolean>(false);

    const onSubmit = () => {
        setError(null);

        if (!name.length || !phone.length) {
            setError("Fill the form")
            return;
        }

        (async () => {
            const res = await $api.post("/orders", {name, phone})
            res.data.success === "ok" && setIsSuccess(true)
        })();
    }

    return (
        <>
            {!isSuccess ?
            <>
                <h1>SIGN UP FOR A CONSULTATION</h1>
                <p>and find out the price</p>
                <Input name="name" labelText={"Your Name"} className={styles.formSubmitYourApplicationInput} onChange={e => setName(e.target.value)} />
                <div className={styles.customInput}>
                    <label htmlFor="phone">Your Phone</label>
                    <InputMask name="phone" mask="+1 (___) ___-__-__" replacement={{ _: /\d/ }} onChange={e => setPhone(e.target.value)} placeholder="+1 (999) 999-99-99" />
                </div>
                {error && <p className={styles.error}>{error}</p>}
                <ButtonForm className={styles.formSubmitYourApplicationInput__button} onClick={onSubmit}>FIND OUT THE PRICE</ButtonForm>
            </> : <h2>Thanks for reaching out! A specialist will contact you shortly</h2>}
        </>
    );
};

export default FormSubmitYourApplication;