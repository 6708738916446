import React, { FC } from 'react';
import styles from "./Modal.module.scss";
import {useAppDispatch, useAppSelector} from "../../../store/reducers/hooks";
import { selectModal } from "../../../store/modal/selectors";
import {hideModal} from "../../../store/modal/modal-slice";

const Modal: FC = () => {
    const dispatch = useAppDispatch();
    const modal = useAppSelector(selectModal);

    if (!modal) {
        return null;
    }

    const hide = () => {
        dispatch(hideModal());
    }

    return (
        <>
            <div className={styles.modal__close} onClick={hide}></div>
            <div className={styles.modal__background}></div>
            <div className={styles.modal}>
                { modal }
            </div>
        </>
    );
};

export default Modal;