import React, {FC, useEffect, useState} from 'react';
import interior_doors_bg from "../../assets/images/interior_doors_bg.png";
import ButtonCustom from "../../components/button/ButtonCustom";
import SectionTop from "../../sections/section_top/SectionTop";
import install_door from "../../assets/images/install_door.jpeg";
import install_door_right from "../../assets/images/install_door_right.jpeg";
import SectionGuarantee from "../../sections/section_guarantee/SectionGuarantee";
import Sliding_Door from "../../assets/images/Easy_Sliding_Door_with_Side_Panel_LR.jpg";
import SectionContactUs from "../../sections/section_contact_us/SectionContactUs";
import SectionSlider from "../../sections/section_slider/SectionSlider";
import $api from "../../api";
import ButtonForm from "../../components/button/ButtonForm";
import {useAppDispatch} from "../../store/reducers/hooks";
import {showModal} from "../../store/modal/modal-slice";
import FormSubmitYourApplication
    from "../../components/forms/form-submit-your-application/form-submit-your-application";

const InteriorDoors: FC = () => {
    const [product, setProduct] = useState<IProduct>()
    const dispatch = useAppDispatch();

    const onForm = () => {
        dispatch(showModal(<FormSubmitYourApplication />))
    }

    useEffect(() => {
        (async () => {
            const res = await $api.get("/products?name=Interior doors")
            setProduct(res.data);
        })()
    }, [])

    return (
        <div>
            <SectionTop backgroundImg={interior_doors_bg}>
                <h1 className={"section_top__title"}>
                    <p>INTERIOR DOOR</p>
                </h1>
                <p className={"section_top__subtitle"}>
                    Interior doors don't just divide rooms
                    and provide privacy, they define the decor
                    and character of a home, providing warmth,
                    beauty and functionality. From traditional options
                    with scalloped and flat panels to shutter doors,
                    French doors or practical accordion doors,
                    there's a style to suit your needs.
                </p>
                <div className={"buttonWrap"}>
                    <ButtonCustom className={"section_top__button"}>GET PRICE</ButtonCustom>
                    <ButtonForm onClick={onForm}>SUBMIT YOUR APPLICATION</ButtonForm>
                </div>
            </SectionTop>
            <SectionGuarantee
                imgLeft={install_door}
                imgRight={install_door_right}
                installation_periods={5}
                on_products_years={20}
            />
            { product && product.productPhotos && <SectionSlider name={product.name} slides={product.productPhotos} /> }
            <SectionContactUs forOrder="door" backgroundOrder={Sliding_Door} />
        </div>
    );
};

export default InteriorDoors;