import React, { FC } from 'react';
import {useAppDispatch} from "../../store/reducers/hooks";
import {showModal} from "../../store/modal/modal-slice";
import FormSubmitYourApplication from "../forms/form-submit-your-application/form-submit-your-application";

interface Props {
    children?: React.ReactNode;
    className?: string;
    onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const ButtonForm: FC<Props> = ({ onClick, children, className}) => {
    return (
        <div className={className ? `${className} button` : "button"} onClick={e => onClick(e)} >
            {children}
        </div>
    );
};

export default ButtonForm;