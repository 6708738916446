import React, {FC} from 'react';
import Slider, {Settings} from "react-slick";

interface Props {
    slides: IProductPhoto[];
    name: string;
}

const SectionSlider:FC<Props> = ({slides, name}) => {
    const settings: Settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    dots: false,
                    infinite: false,
                    speed: 500,
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    dots: false,
                    infinite: false,
                    speed: 500,
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div className={"section_slider"}>
            <div className="container">
                <div className={"section_slider__content"}>
                    <div className={"section_slider__top"}>
                        <p>PHOTOS OF COMPLETED WORKS</p>
                    </div>
                    <Slider {...settings}>
                        {slides?.length && slides.map(item => <div key={item.id} ><img src={process.env.NODE_ENV === "development" ? `${process.env.REACT_APP_URL_DEVELOPMENT}/public/${name.replace(/ /g,'')}/${item.namePhoto}` : `${process.env.REACT_APP_URL_PRODUCTION}/public/${name.replace(/ /g,'')}/${item.namePhoto}`} alt=""/></div>)}
                    </Slider>
                </div>
            </div>
        </div>
    );
};

export default SectionSlider;