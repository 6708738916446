import React from 'react';
import './App.scss';
import NavBar from "./components/layout/navbar/NavBar";
import AppRouter from "./components/AppRouter";
import Footer from "./components/layout/footer/Footer";
import Modal from "./components/layout/modal/Modal";

function App() {
    return (
    <>
        <Modal />
        <NavBar />
        <AppRouter />
        <Footer />
    </>
  );
}

export default App;
