import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import React from "react";

interface IModalState {
  modal: React.ReactNode;
  isLoading: 'idle' | 'pending' | 'succeeded' | 'failed';
  errorMessage: null | string;
}

const initialState: IModalState = {
    modal: null,
    isLoading: "idle",
    errorMessage: null,
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
      showModal: (state, action: PayloadAction<React.ReactNode>) => {
          state.modal = action.payload;
      },
      hideModal: (state) => {
          state.modal = null;
      }
  },
});

export const { showModal, hideModal } = modalSlice.actions;
export default modalSlice;
