import React, {FC, useEffect, useState} from 'react';
import sliding_window_bg from "../../assets/images/sliding_window_bg.png";
import ButtonCustom from "../../components/button/ButtonCustom";
import SectionTop from "../../sections/section_top/SectionTop";
import guarant_window_left from "../../assets/images/guarant-window-left.png";
import guarant_window_right from "../../assets/images/guarant-window-right.png";
import SectionGuarantee from "../../sections/section_guarantee/SectionGuarantee";
import SectionWindowsDelivery from "../../sections/section_windows_delivery/SectionWindowsDelivery";
import order from "../../assets/images/order-bg.png";
import SectionContactUs from "../../sections/section_contact_us/SectionContactUs";
import SectionSlider from "../../sections/section_slider/SectionSlider";
import $api from "../../api";
import ButtonForm from "../../components/button/ButtonForm";
import {useAppDispatch} from "../../store/reducers/hooks";
import {showModal} from "../../store/modal/modal-slice";
import FormSubmitYourApplication
    from "../../components/forms/form-submit-your-application/form-submit-your-application";

const SlidingWindow: FC = () => {
    const [product, setProduct] = useState<IProduct>()
    const dispatch = useAppDispatch();

    const onForm = () => {
        dispatch(showModal(<FormSubmitYourApplication />))
    }

    useEffect(() => {
        (async () => {
            const res = await $api.get("/products?name=Sliding window")
            setProduct(res.data);
        })()
    }, [])

    return (
        <div>
            <SectionTop backgroundImg={sliding_window_bg}>
                <h1 className={"section_top__title"}>
                    <p>SLIDING WINDOW</p>
                </h1>
                <p className={"section_top__subtitle"}>
                    Sliding windows glide open horizontally from one side to the other. Sliding on a track, they require no extra space to operate making them a popular choice along patios, porches, tight walkways or anywhere you wouldn’t want an outward swinging window.
                    Sliding windows offer easy and controlled access
                    to fresh air and provide excellent energy
                    efficiency when closed.
                </p>
                <div className={"buttonWrap"}>
                    <ButtonCustom className={"section_top__button"}>GET PRICE</ButtonCustom>
                    <ButtonForm onClick={onForm}>SUBMIT YOUR APPLICATION</ButtonForm>
                </div>
            </SectionTop>
            <SectionGuarantee
                imgLeft={guarant_window_left}
                imgRight={guarant_window_right}
                installation_periods={1}
                on_products_years={10}
            />
            { product && product.productPhotos && <SectionSlider name={product.name} slides={product.productPhotos} /> }
            <SectionWindowsDelivery />
            <SectionContactUs forOrder="window" backgroundOrder={order} />
        </div>
    );
};

export default SlidingWindow;