import React, {FC} from 'react';
import service1 from "../../assets/images/service-1.png";
import service2 from "../../assets/images/service-2.png";
import service5 from "../../assets/images/service-5.png";
import service6 from "../../assets/images/service-6.png";
import ButtonCustom from "../../components/button/ButtonCustom";

const SectionInterested: FC = () => {
    return (
        <div className="service">
            <div className="container">
                <div className={"service__top"}>
                    <p>WHICH OF THESE SERVICES ARE YOU INTERESTED IN?</p>
                </div>
                <div className={"service__content"}>
                    <div>
                        <img src={service1} alt=""/>
                        <p>Window replacement</p>
                    </div>
                    <div>
                        <img src={service2} alt=""/>
                        <p>Window repair service</p>
                    </div>
                    <div>
                        <img src={service5} alt=""/>
                        <p>Restoring wooden windows</p>
                    </div>
                    <div>
                        <img src={service6} alt=""/>
                        <p>Door installation and repair</p>
                    </div>
                </div>
                <div className={"service__button"}>
                    <ButtonCustom>GET PRICE</ButtonCustom>
                </div>
            </div>
        </div>
    );
};

export default SectionInterested;