import React, {FC, useEffect, useState} from 'react';
import classes from "./Main.module.scss"
import hero from "../../assets/images/hero-bg.png"
import ButtonCustom from "../../components/button/ButtonCustom";
import SectionSlider from "../../sections/section_slider/SectionSlider";
import SectionReviews from "../../sections/section_reviews_slider/SectionReviews";
import $api from "../../api";
import SectionInterested from "../../sections/section_interested/SectionInterested";
import ButtonForm from "../../components/button/ButtonForm";
import {useAppDispatch} from "../../store/reducers/hooks";
import {showModal} from "../../store/modal/modal-slice";
import FormSubmitYourApplication
    from "../../components/forms/form-submit-your-application/form-submit-your-application";

const Main: FC = () => {
    const [product, setProduct] = useState<IProduct>();
    const dispatch = useAppDispatch();

    const onForm = () => {
        dispatch(showModal(<FormSubmitYourApplication />))
    }

    useEffect(() => {
        (async () => {
            const res = await $api.get("/products?name=Double window")
            setProduct(res.data);
        })();
    }, [])

    return (
        <div>
            <div className={classes.main} style={{ backgroundImage: `url(${hero})` }}>
                <div className="container">
                    <h1 className={classes["main__hero--title"]}>
                        <p>WINDOW AND DOOR INSTALLATION</p>
                        <p style={{color: "#1399C4"}}>IN NEW YORK</p>
                    </h1>
                    <div className={"buttonWrap"}>
                        <ButtonCustom>GET PRICE</ButtonCustom>
                        <ButtonForm onClick={onForm}>SUBMIT YOUR APPLICATION</ButtonForm>
                    </div>
                </div>
            </div>
            <SectionInterested />
            <SectionReviews />
            { product && product.productPhotos && <SectionSlider name={product.name} slides={product.productPhotos} /> }
        </div>
    );
};

export default Main;